.change-password-form {
    max-width: 80%;
    min-width: 800px;
    margin: 0 auto;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  .change-password-form div {
    margin-bottom: 15px;
  }
  
  .change-password-form label {
    display: block;
    margin-bottom: 5px;
  }
  
  .change-password-form input {
    width: 100%;
    padding: 8px;
    box-sizing: border-box;
    border: 1px solid #ccc;
    border-radius: 3px;
    font-size: 14px;
    margin-top: 5px;
  }
  
  .change-password-form button {
    background-color: #4caf50;
    color: #fff;
    padding: 10px;
    border: none;
    border-radius: 3px;
    cursor: pointer;
    font-size: 16px;
  }
  
  .change-password-form button:hover {
    background-color: #45a049;
  }