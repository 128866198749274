@import './assets/styles/variables';

@font-face {
  font-family: 'Aharoni', 'Roboto';
  font-style: normal;
  font-weight: normal;
  src: url('/assets/fonts/Aharoni.ttf');
  background: $background-color;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Roboto', sans-serif;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: subpixel-antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-shadow: rgba(0, 0, 0, .01) 0 0 1px;
  scroll-behavior: smooth; 

  &:focus { outline: none; }

  &::-webkit-scrollbar { width: 5px; }

  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px lightgray;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background: gray;
    border-radius: 10px;
  }
}

html, body { 
  height: 100%;
}

html {
  body {
    background: $background-color;
    border-radius: 0.25rem;
    height: 100%;

    #root {
      height: 100%;
    }
  }
}

main { 
  display: flex;
  flex-flow: row wrap;
  justify-content: space-around;
  width: 100%;
  height: auto;
  overflow-x: auto;
  padding: 10px;
}

.flex { display: flex; }
.flex-row { flex-direction: row; }
.flex-wrap { flex-wrap: wrap; }
.flex-column { flex-direction: column; }
.flex-center { align-items: center; justify-content: center; }
.flex-even { justify-content: space-evenly; }
.flex-around { justify-content: space-around; }
.flex-between { justify-content: space-between; }
.w-100 { width: 100%; }
.h-100 { height: 100% }
.paddless { padding: 0; }
.mt-25 { margin-top: 25px;}

pre {
  font-weight: normal;
}
