.payable-pix-container {
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 30px;

  span { 
    margin-top: 40px;
  }

  img { margin-top: 40px; }
}