.message { 
  margin-top: 15px;
  font-size: 22px;
}

.action-ctn {
  display: flex;
  justify-content: space-around;
  margin: auto 0 20px;
  width: 80%;
}