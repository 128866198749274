@import '../../assets/styles/variables.scss';

.rastreio-page {
  height: 100%;
  padding: 0 !important;
  background: #ffde00;


  h1, h2, h3, h4, h5 {
    color: #222;
  }

  .images-header {
    display: flex;
    justify-content: center;
    align-items: center;


    .tire { 
      max-width: 100px;
      height: auto;
    }
  }

  .form-container {
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: space-around;
    width: 100%;
    padding: 0;

    h2 { 
      margin-top: 25px; 
      font-size: 30px;
      color: #222;
    }

    .container-rastreio {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin: 30px;
    }

    .disfoque {

      opacity: 0.5;
    }

    .container-arrow {

      justify-content: center;
      align-items: center;
      height: 100%;
      display: flex;

      .arrow {
        width: 100px;
      }
    }

    .image-container {
      
      height: 270px;
      max-width: 250px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      width: 20%;

      h3 {
        position: absolute;
        top: 70%;
        
      }

      h2 {
        font-size: 22px;
        white-space:nowrap;
        color: #222;
         
      }

      
    }

    .image-status {
      width: 150px;
      height: auto;
      max-width: 100%;
      padding-top: 50px;
    }

    .logo { 
      align-self: center;
      width: 100%;
      max-width: 250px;
      height: auto; 
    }

    .partner { 
      width: 100%;
      max-width: 250px;
      margin-top: 25px;
      height: auto;
      float: bottom;
    }
  }

  .Mui-focused {
    fieldset {
      border-color: $secondary-color !important;
    } 
  }

  label.Mui-focused { 
    color: black !important; 
  }

  .blink {
    animation: blinker 2s linear infinite;
  }

  @keyframes blinker {
    50% {
      opacity: 0;
    }
  }

  @media screen and (max-width: 1050px) {

    .form-container {

      .image-container {
        height: 250px;
      }
    }

  }

  @media screen and (max-width: 950px) {

    .form-container {

      .image-container {
        height: 230px;
      }
    }

  }

  @media screen and (max-width: 850px) {

    .form-container {

      .image-container {
        height: 210px;
      }
    }

  }


  @media screen and (max-width: 700px) {

    .form-container {
      .container-rastreio {
        display: flex;
        gap: 20px;
        flex-direction: column;
        align-items: center;
      } 
      .image-container {
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 70%;
        text-align: center;

        .image-status {

        }
  
        h2 {
          font-size: 18px;
          white-space:nowrap;
          color: #222;
        }
      }
    }

    
  }
  
}